import { createSlice } from "@reduxjs/toolkit";
import { fetchColumns } from "./columnsThunks";

const columnsSlice = createSlice({
  name: "columns",
  initialState: {
    UserManagementRoles: {
      value: {}, // Object to store fields by configType
      loading: {},
      error: {},
    },
    UserManagementStatuses: {
      value: {}, // Object to store fields by configType
      loading: {},
      error: {},
    },
    UserManagementUsers: {
      value: {}, // Object to store fields by configType
      loading: {},
      error: {},
    },
    DocumentManagementDocuments: {
      value: {}, // Object to store fields by configType
      loading: {},
      error: {},
    },
    DocumentManagementStatus: {
      value: {}, // Object to store fields by configType
      loading: {},
      error: {},
    },
    DocumentManagementType: {
      value: {}, // Object to store fields by configType
      loading: {},
      error: {},
    },
    DocumentManagementExtensions: {
      value: {}, // Object to store fields by configType
      loading: {},
      error: {},
    },
  },
  reducers: {
    editColumns: (state, action) => {
      const { configName, data } = action.payload;
      state[configName].value = data; // Update fields for the specific configType
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchColumns.pending, (state, action) => {
        const configName = action.meta.arg.configName;
        state[configName] = { loading: true, value: [], error: null };
      })
      .addCase(fetchColumns.fulfilled, (state, action) => {
        const { configName, data } = action.payload;
        state[configName] = {
          loading: false,
          value: data,
          error: null,
        };
      })
      .addCase(fetchColumns.rejected, (state, action) => {
        const configName = action.meta.arg.configName; // Correctly access configType from action.meta.arg
        state[configName] = {
          loading: false,
          value: [],
          error: action.payload,
        };
      });
  },
});

export const { editColumns } = columnsSlice.actions;

export default columnsSlice.reducer;
