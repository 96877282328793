import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosPostTest } from "../../../../helpers/axiosHelper";
import {
  getBusinessID,
  getUserEmail,
  getUid,
} from "../../../../helpers/getLocalStorageHelper";

export const fetchColumns = createAsyncThunk(
  "columns/fetchColumns",
  async ({ configType, configName }, thunkAPI) => {
    try {
      const params = {
        currentEmailAddress: getUserEmail(),
        filter: {
          configType: configType,
          configName: configName,
          businessId: getBusinessID(),
          userUid: getUid(),
        },
      };
      const response = await axiosPostTest(
        process.env.REACT_APP_GET_APP_BUSINESS_COLUMNS,
        params
      );

      const data = response.data; // Assuming response.data[0] is an array of fields
      if (!data) {
        throw new Error("No columns returned from API.");
      }
      console.log("response", response);
      const extractedConfigs = data.map((item) => item.config);
      return { configName, data: extractedConfigs }; // Ensure the returned object has fields
    } catch (error) {
      console.error("Error fetching columns:", error);
      return thunkAPI.rejectWithValue(
        error.response?.data || "Error fetching columns"
      );
    }
  }
);
