import { configureStore } from "@reduxjs/toolkit";
import settingsSliceReducer from "../reduxnew/settingsSliceReducer";
import fieldsSliceReducer from "../reduxnew/fieldsSliceReducer";
import userManagementSlice from "../reduxnew/userManagementStatusReducer";
import columnsSlice from "../reduxnew/columnsSliceReducer";

const store = configureStore({
  reducer: {
    settings: settingsSliceReducer, // 'settings' will be the key where settingsSliceReducer's state is stored
    fields: fieldsSliceReducer,
    userManagement: userManagementSlice,
    columns: columnsSlice,
  },
  // devTools: process.env.NODE_ENV !== "production",
});

console.log("Initial state:", store.getState());

export default store;
