import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosPostTest } from "../../../../helpers/axiosHelper";
import {
  getBusinessID,
  getUserEmail,
  getBusinessName,
  getFullName,
} from "../../../../helpers/getLocalStorageHelper";

export const createSetting = createAsyncThunk(
  "settings/create",
  async ({ configType, configName, value }, thunkAPI) => {
    try {
      const params = {
        configType: configType,
        configName: configName,
        config: JSON.stringify(value),
        createdDate: new Date().toISOString(),
        createdBy: getUserEmail(),
        updatedDate: new Date().toISOString(),
        updatedBy: getUserEmail(),
        businessId: getBusinessID(),
        businessName: getBusinessName(),
        currentEmailAddress: getUserEmail(),
        filter: {
          table: "settings",
        },
      };
      const response = await axiosPostTest(
        process.env.REACT_APP_APP_BUSINESS_SETTINGS_CREATE_SETTINGS,
        params
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(
        error.response?.data || "Error creating setting"
      );
    }
  }
);

export const fetchSettings = createAsyncThunk(
  "settings/fetchSettings",
  async ({ configType, configName }, thunkAPI) => {
    try {
      const params = {
        currentEmailAddress: getUserEmail(),
        filter: {
          table: "settings",
          configType: configType,
          configName: configName,
          businessId: getBusinessID(),
        },
      };
      const response = await axiosPostTest(
        process.env.REACT_APP_APP_BUSINESS_SETTINGS_GET_SETTINGS,
        params
      );
      console.log("Response after fetch post:", response.data); // Log the response data
      return response.data; // Ensure this contains the settings or roles data
    } catch (error) {
      console.error("Error fetching settings:", error); // Log any errors
      return thunkAPI.rejectWithValue(
        error.response?.data || "Error fetching settings"
      );
    }
  }
);

export const updateSettings = createAsyncThunk(
  "settings/updateSettings",
  async ({ value, uid }, thunkAPI) => {
    try {
      const params = {
        currentEmailAddress: getUserEmail(),
        config: JSON.stringify(value),
        fullName: getFullName(),
        filter: {
          uid,
          table: "settings",
        },
      };
      const response = await axiosPostTest(
        process.env.REACT_APP_APP_BUSINESS_SETTINGS_EDIT_SETTINGS,
        params
      );
      console.log("Response after update post:", response.data); // Log the response data
      return response.data; // Ensure this contains the settings or roles data
    } catch (error) {
      console.error("Error updating settings:", error); // Log any errors
      return thunkAPI.rejectWithValue(
        error.response?.data || "Error updating settings"
      );
    }
  }
);
