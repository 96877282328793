import { createSlice } from "@reduxjs/toolkit";
import { fetchUserManagementSettings } from "./userManagementStatusThunks";

const userManagementSlice = createSlice({
  name: "userManagement",
  initialState: {
    UserManagementRoles: {
      value: [],
      loading: false,
      error: null,
    },
    UserManagementStatus: {
      value: [],
      loading: false,
      error: null,
    },
    DocumentManagementStatus: {
      value: [],
      loading: false,
      error: null,
    },
    DocumentManagementType: {
      value: [],
      loading: false,
      error: null,
    },
  },

  reducers: {
    addUserManagementSetting: (state, action) => {
      const { configName, data } = action.payload;
      // console.log("action payload: ", action.payload);
      // console.log("Action Type:", action.type);
      // console.log("Before mutation:", state[configName]);
      // console.log("State before mutation:", JSON.stringify(state, null, 2));

      if (!state[configName]) {
        // console.log(`Initializing ${configName}`);
        state[configName] = { value: [] };
      }

      state[configName].value.push(data);
      // console.log("state[configName]: ", state[configName]);
      // console.log("State after mutation:", JSON.stringify(state, null, 2));
    },
    editUserManagementSetting: (state, action) => {
      const { configName, data } = action.payload;
      console.log("data: ", data);
      if (!state[configName]) {
        console.warn(`Config "${configName}" does not exist in state.`);
        return;
      }

      const index = state[configName].value.findIndex(
        (item) => item.uid === data.uid
      );

      if (index === -1) {
        console.warn(
          `Item with uid "${data.uid}" not found in "${configName}".`
        );
        return;
      }

      // Update the specific item in the array
      state[configName].value[index] = {
        ...state[configName].value[index],
        ...data, // Merge the new data with the existing object
      };

      console.log(
        `State after updating "${data.uid}" in "${configName}":`,
        JSON.stringify(state[configName], null, 2)
      );
    },
    removeUserManagementSetting: (state, action) => {
      const { configName, data } = action.payload;

      if (!state[configName]) {
        console.warn(`Config "${configName}" does not exist in state.`);
        return;
      }

      if (!Array.isArray(state[configName].value)) {
        console.error(`Value for "${configName}" is not an array.`);
        return;
      }

      // Handle case where data is an array of UIDs
      if (Array.isArray(data)) {
        state[configName].value = state[configName].value.filter(
          (value) => !data.includes(value.uid)
        );
      } else {
        // Fallback for single UID
        state[configName].value = state[configName].value.filter(
          (value) => value.uid !== data
        );
      }
    },
  },

  extraReducers: (builder) => {
    // Handle dynamic action types using the thunk's action type structure
    builder
      .addCase(fetchUserManagementSettings.pending, (state, action) => {
        const configName = action.meta.arg.configName;
        state[configName] = { loading: true, value: [], error: null }; // Set loading state dynamically
      })
      .addCase(fetchUserManagementSettings.fulfilled, (state, action) => {
        const { configName, data } = action.payload;
        state[configName] = { loading: false, value: data, error: null }; // Update data for the respective configName
      })
      .addCase(fetchUserManagementSettings.rejected, (state, action) => {
        const configName = action.meta.arg.configName;
        state[configName] = {
          loading: false,
          value: [],
          error: action.payload,
        }; // Handle rejection error
      });
  },
});

export const {
  addUserManagementSetting,
  removeUserManagementSetting,
  editUserManagementSetting,
} = userManagementSlice.actions;
export default userManagementSlice.reducer;
