import React from "react";

export default function Home() {
  return (
    <>
      <header>
        <h1>Welcome to ISMSVision</h1>
      </header>
      <main>
        <div>This is the Home Page</div>
      </main>
    </>
  );
}
