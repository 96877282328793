export const setUserSession = (
  uid,
  businessId,
  businessName,
  emailAddress,
  user,
  token,
  profileImageID
) => {
  localStorage.setItem("uid", uid);
  localStorage.setItem("businessId", businessId);
  localStorage.setItem("businessName", businessName);
  localStorage.setItem("emailAddress", emailAddress);
  localStorage.setItem("user", JSON.stringify(user));
  localStorage.setItem("token", token);
  localStorage.setItem("profileImageID", profileImageID);
};

export const setProfileImageURL = (profileImageURL) => {
  localStorage.setItem("profileImageURL", profileImageURL);
};

export const setRefreshToken = (newToken) => {
  localStorage.setItem("token", newToken);
};

export const resetUserSession = () => {
  localStorage.removeItem("uid");
  localStorage.removeItem("businessId");
  localStorage.removeItem("businessName");
  localStorage.removeItem("emailAddress");
  localStorage.removeItem("user");
  localStorage.removeItem("token");
  localStorage.removeItem("profileImageID");
  localStorage.removeItem("profileImageURL");
  localStorage.removeItem("UserPreferences-UserManagementColumns");
};
