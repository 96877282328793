import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosPostTest } from "../../../../helpers/axiosHelper";
import {
  getBusinessID,
  getUserEmail,
} from "../../../../helpers/getLocalStorageHelper";

export const fetchUserManagementSettings = createAsyncThunk(
  "status/fetchstatus", // Static base action type
  async ({ tableName, configType, configName }, thunkAPI) => {
    try {
      const params = {
        currentEmailAddress: getUserEmail(),
        filter: {
          table: tableName,
          configType: configType,
          configName: configName,
          businessId: getBusinessID(),
        },
      };

      const response = await axiosPostTest(
        process.env.REACT_APP_APP_BUSINESS_SETTINGS_GET_SETTINGS,
        params
      );
      console.log("response", response);
      const data = response.data; // Assuming response.data[0] is an array of fields
      if (!data) {
        throw new Error("No fields returned from API.");
      }

      const extractedConfigs = data.map((item) => ({
        uid: item.uid,
        config: item.config,
      }));
      console.log("extractedConfigs", extractedConfigs);
      return {
        configName,
        data: extractedConfigs,
      }; // Return the configName and data from the API
    } catch (error) {
      console.error("Error fetching fields:", error);
      return thunkAPI.rejectWithValue(
        error.response?.data || "Error fetching fields"
      ); // Reject with error message
    }
  }
);
