import { createSlice } from "@reduxjs/toolkit";
import { fetchFields } from "./fieldsThunks";

const fieldsSlice = createSlice({
  name: "fields",
  initialState: {
    roles: {
      value: {}, // Object to store fields by configType
      loading: {},
      error: {},
    },
    status: {
      value: {}, // Object to store fields by configType
      loading: {},
      error: {},
    },
  },
  reducers: {
    setFields: (state, action) => {
      const { configType, fields } = action.payload;
      state.fields[configType] = fields; // Update fields for the specific configType
    },
    clearFields: (state, action) => {
      const { configType } = action.payload || {};
      if (configType) {
        delete state.fields[configType];
        delete state.loading[configType];
        delete state.error[configType];
      } else {
        state.fields = {}; // Clear all fields
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchFields.pending, (state, action) => {
        const configName = action.meta.arg.configName;
        state[configName] = { loading: true, value: [], error: null };
      })
      .addCase(fetchFields.fulfilled, (state, action) => {
        const { configName, data } = action.payload;
        state[configName] = {
          loading: false,
          value: data,
          error: null,
        };
      })
      .addCase(fetchFields.rejected, (state, action) => {
        const configName = action.meta.arg.configName; // Correctly access configType from action.meta.arg
        state[configName] = {
          loading: false,
          value: [],
          error: action.payload,
        };
      });
  },
});

export const { setFields, clearFields } = fieldsSlice.actions;

export default fieldsSlice.reducer;
