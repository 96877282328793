import { createSlice } from "@reduxjs/toolkit";
import { createSetting, fetchSettings, updateSettings } from "./settingsThunks";

const settingsSlice = createSlice({
  name: "settings",
  initialState: {
    items: [],
    loading: false,
    error: null,
    requestStatus: null,
  },
  reducers: {
    updateSettingOptimistically: (state, action) => {
      const { id, updatedSettings } = action.payload;
      const index = state.items.findIndex((item) => item.id === id);
      if (index !== -1) {
        state.items[index] = { ...state.items[index], ...updatedSettings };
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createSetting.pending, (state) => {
        state.loading = true;
        state.requestStatus = "pending"; // Set requestStatus to 'pending'
      })
      .addCase(createSetting.fulfilled, (state, action) => {
        state.loading = false;
        state.requestStatus = "fulfilled"; // Set requestStatus to 'fulfilled'
        console.log("createSetting fulfilled payload:", action.payload); // Debugging payload
        try {
          const newItem = {
            ...action.payload, // Preserve other fields like `businessId`
            config:
              typeof action.payload.config === "string"
                ? JSON.parse(action.payload.config)
                : action.payload.config, // Parse the config field if it's a string
          };
          state.items.push(newItem); // Add the new setting
        } catch (error) {
          console.error("Error parsing config:", error);
          state.error = "Invalid data received from server.";
        }
      })

      .addCase(createSetting.rejected, (state, action) => {
        state.loading = false;
        state.requestStatus = "rejected"; // Set requestStatus to 'rejected'
        state.error = action.payload;
      })
      .addCase(fetchSettings.pending, (state) => {
        state.requestStatus = "pending"; // Set requestStatus to 'pending'
        state.loading = true;
      })
      .addCase(fetchSettings.fulfilled, (state, action) => {
        state.loading = false;
        state.requestStatus = "fulfilled"; // Set requestStatus to 'fulfilled'
        console.log("Fetched settings:", action.payload); // Debugging
        // Parse the config field for each setting in the response
        try {
          state.items = action.payload.map((item) => {
            if (item.config) {
              try {
                const config =
                  typeof item.config === "string"
                    ? JSON.parse(item.config)
                    : item.config;
                console.log("Parsed config:", config);
                return { ...item, config };
              } catch (e) {
                console.error("Error parsing config:", e);
                return { ...item, config: null }; // If parsing fails, set config to null
              }
            }
            return item; // Handle case where `config` is not available
          });
        } catch (error) {
          console.error("Error processing fetched settings:", error);
          state.error = "Failed to process settings data.";
        }
      })
      .addCase(fetchSettings.rejected, (state, action) => {
        state.loading = false;
        state.requestStatus = "rejected"; // Set requestStatus to 'rejected'
        state.error = action.payload;
      })
      .addCase(updateSettings.pending, (state) => {
        state.loading = true;
        state.requestStatus = "pending";
      })
      .addCase(updateSettings.fulfilled, (state, action) => {
        state.loading = false;
        state.requestStatus = "fulfilled";
        // Handle the success, no further updates needed because the optimistic update already happened
      })
      .addCase(updateSettings.rejected, (state, action) => {
        state.loading = false;
        state.requestStatus = "rejected";
        state.error = action.payload;
        // Optionally rollback the optimistic update if the request fails
      });
  },
});

export default settingsSlice.reducer;
