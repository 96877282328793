import { NavLink } from "react-router-dom";

export default function Navigation() {
  return (
    <div className="header">
      <NavLink
        exact="true"
        className={({ isActive }) => (isActive ? "active" : "")}
        to="/"
      >
        Home
      </NavLink>
      <NavLink
        className={({ isActive }) => (isActive ? "active" : "")}
        to="/login"
      >
        Account
      </NavLink>
      {/* <NavLink
        className={({ isActive }) => (isActive ? "active" : "")}
        to="/register"
      >
        Register
      </NavLink>
      <NavLink
        className={({ isActive }) => (isActive ? "active" : "")}
        to="/login"
      >
        Login
      </NavLink> */}
      {/* <NavLink
        className={({ isActive }) => (isActive ? "active" : "")}
        to="/app"
      ></NavLink> */}
    </div>
  );
}
