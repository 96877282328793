import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { axiosPost } from "./helpers/axiosHelper";
import { resetUserSession } from "./helpers/setLocalStorageHelper";
import { getUserEmail, getToken } from "./helpers/getLocalStorageHelper";
import MainRouteSetup from "./routes/MainRouteSetup";
import { useDispatch } from "react-redux";
import { fetchColumns } from "./components/app/testing/reduxnew/columnsThunks";

export default function App() {
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const verifyTokenAPIURL = process.env.REACT_APP_VERIFY_API_URL;
  const token = getToken();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const authenticate = async () => {
      if (!token) {
        setIsAuthenticating(false);
        navigate("/login");
        return;
      }

      const requestBody = {
        currentEmailAddress: getUserEmail(),
        token: token,
      };

      try {
        const response = await axiosPost(verifyTokenAPIURL, requestBody);

        if (response.result === "success") {
          setIsAuthenticating(false);
          dispatch(
            fetchColumns({
              configType: "columns",
              configName: "DocumentManagementDocuments",
            })
          );

          dispatch(
            fetchColumns({
              configType: "columns",
              configName: "UserManagementUsers",
            })
          );

          dispatch(
            fetchColumns({
              configType: "columns",
              configName: "UserManagementStatus",
            })
          );

          dispatch(
            fetchColumns({
              configType: "columns",
              configName: "UserManagementRoles",
            })
          );

          dispatch(
            fetchColumns({
              configType: "columns",
              configName: "DocumentManagementStatus",
            })
          );
          dispatch(
            fetchColumns({
              configType: "columns",
              configName: "DocumentManagementType",
            })
          );
          dispatch(
            fetchColumns({
              configType: "columns",
              configName: "DocumentManagementExtensions",
            })
          );
        } else {
          resetUserSession();
          setIsAuthenticating(false);
          navigate("/login");
        }
      } catch (error) {
        resetUserSession();
        setIsAuthenticating(false);
        navigate("/login");
      }
    };
    authenticate();
  }, [token]);

  if (isAuthenticating && token) {
    return <div className="content"> Authenticating...</div>;
  }

  return <MainRouteSetup />;
}
